@import url(//fonts.googleapis.com/css?family=Patrick+Hand+SC|Bangers|Happy+Monkey);
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f1f1f1
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}
body, html {
	margin: 0;
	padding: 0;
	background: #f1f1f1
}
#root {
	justify-content: center;
	flex-wrap: wrap
}
#root, .App {
	display: flex
}
.App {
	margin: 10% 0;
	background: #fff;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 10px;
	box-shadow: 0 6px 6px -6px #000;
	border: 2px solid #000;
	border-width: 5px 3px 3px 5px;
	border-radius: 95% 4% 97% 5%/4% 94% 2% 95%;
	flex-flow: column;
	align-items: center
}
.App, .banner {
	text-align: center
}
.banner div a {
	margin: auto;
	color: #000
}
.banner div a:hover {
	color: #000
}
.banner div a h2 {
	padding: 4px;
	margin: 22px;
	-webkit-transition: all .5s ease;
	transition: all .5s ease;
	overflow: hidden
}
.banner div a h2 .insider {
	background-color: #fff;
	width: 100%;
	height: 20px;
	position: absolute;
	left: -240px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg)
}
.banner .cons a h2:hover {
	background-color: #3649f4;
	color: #fff
}
.banner .emp a h2:hover {
	background-color: #f44336;
	color: #fff
}
.banner .surv a h2:hover {
	background-color: #8836f4;
	color: #fff
}
.banner div a h2:hover .insider {
	transition: all .3s ease;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	left: 250px
}
.comic {
	display: flex;
	flex-flow: column;
	font-family: Comic Sans, cursive;
	padding: 1vmin
}
.panel .image {
	min-width: 200px
}
.inp {
	margin-left: -35px
}
.inp input {
	width: 90%
}
h2 {
	display: inline-block;
	padding: .25em;
	background-color: #fff;
	border: 5px solid #454546;
	-webkit-transform: skewX(-12deg) rotate(-3deg);
	transform: skewX(-12deg) rotate(-3deg);
	box-shadow: 8px 8px 0 -1px #454546
}
h2:after {
	display: none
}
h2.b {
	font-family: classic-comic, sans-serif;
	margin-left: .8em;
	margin-top: -13px!important
}
.panel {
	box-shadow: 0 6px 6px -6px #000;
	display: inline-block;
	flex: 1 1;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	margin: 1vmin;
	overflow: hidden;
	position: relative
}
.panel, .text {
	background-color: #fff;
	border: 2px solid #000
}
.text {
	margin: 0;
	padding: 3px 10px
}
.top-left {
	left: -6px;
	top: -2px
}
.bottom-right, .top-left {
	position: absolute;
	-webkit-transform: skew(-15deg);
	transform: skew(-15deg)
}
.bottom-right {
	bottom: -2px;
	right: -6px
}
.speech {
	background-color: #fff;
	border: 2px solid #000;
	border-radius: 12px;
	display: inline-block;
	margin: .5em;
	padding: .5em 1em;
	position: relative
}
.speech:before {
	border-left: 12px solid transparent;
	border-top: 12px solid transparent;
	border-color: #000 transparent transparent #000;
	border-width: 12px;
	bottom: -24px;
	left: 24px
}
.speech:after, .speech:before {
	border-style: solid;
	content: "";
	height: 0;
	position: absolute;
	-webkit-transform: skew(-15deg);
	transform: skew(-15deg);
	width: 0
}
.speech:after {
	border-left: 10px solid transparent;
	border-top: 10px solid transparent;
	border-color: #fff transparent transparent #fff;
	border-width: 10px;
	bottom: -19px;
	left: 27px
}
.divider {
	width: 50%
}
.copyright-gen {
	margin: 20px 0;
	display: flex;
	width: 100%;
	justify-content: space-between
}
.copyright-gen .coder-gen {
	opacity: 0;
	margin-left: 20px
}
.social_div {
	display: flex;
	justify-content: center;
	flex-flow: row wrap
}
.test-panel {
	overflow: visible;
	border: unset;
	box-shadow: unset
}
.test-text {
	width: 60%;
	left: 1em;
	-webkit-transform: skew(-15deg);
	transform: skew(-15deg);
	background: #fff;
	box-shadow: 3px 5px 10px rgba(0, 0, 0, .1);
	z-index: 1;
	border-right: 3px solid;
	font-family: Happy Monkey, cursive;
	color: #222;
	padding: 10px;
	border-left: 1px solid rgba(0, 0, 0, .51);
	margin-bottom: 20px;
	margin-top: -20px;
	width: 90%;
	box-shadow: 3px 5px 10px 3px rgba(0, 0, 0, .28);
	left: unset
}
.test-text p {
	display: block;
	-webkit-margin-before: 1em;
	margin-block-start: 1em;
	-webkit-margin-after: 1em;
	margin-block-end: 1em;
	-webkit-margin-start: 0;
	margin-inline-start: 0;
	-webkit-margin-end: 0;
	margin-inline-end: 0
}
.test-image {
	width: 42%;
	height: 100%;
	right: -1.5em;
	top: 0;
	-webkit-transform: skew(-15deg);
	transform: skew(-15deg);
	position: absolute;
	overflow: hidden
}
.h1head {
	display: block;
	-webkit-margin-before: .83em;
	margin-block-start: .83em;
	-webkit-margin-after: .83em;
	margin-block-end: .83em;
	-webkit-margin-start: 0;
	margin-inline-start: 0;
	-webkit-margin-end: 0;
	margin-inline-end: 0;
	font-weight: 700;
	font-family: Bangers, sans-serif;
	color: #222;
	font-size: 2em;
	text-shadow: -1px -1px #000, 1px 1px #000, 2px 2px #000;
	font-weight: 400;
	margin: .25em 0;
	font-size: 1.5em;
	color: #fa9939;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	border: none;
	box-shadow: none;
	font-size: 2.5em
}
.empImg {
	border: 1px solid #000;
	padding: 10px;
	margin: 5px;
	box-shadow: -1px 2px 15px -4px rgba(0, 0, 0, .28)
}
.comicEmp {
	width: -webkit-min-content;
	width: -moz-min-content;
	width: min-content
}
.con_gen {
	color: #2185d0;
	font-family: Bangers, sans-serif;
	text-shadow: -1px -1px rgba(125, 160, 224, .25), 1px 1px rgba(45, 111, 179, .05);
	padding: 2px;
	font-size: 2em
}
@media screen and (max-width:979px) {
	@media screen and (max-width: 979px) {
		.panel .image {
			width:unset!important
		}
		.test-text {
			-webkit-transform: skew(-3deg);
			transform: skew(-3deg);
			margin-left: 15px
		}
		.comicEmp {
			width: unset
		}
	}
}
@media screen and (max-width:500px) {
	.App {
		padding: 0
	}
	.panel .image {
		width: unset!important
	}
	.test-text {
		-webkit-transform: skew(-.5deg);
		transform: skew(-.5deg);
		margin-left: 15px
	}
	.react-audio-player {
		width: 100%
	}
}
.social-facebook-wrapper {
	display: flex;
	flex-direction: column
}
.social-facebook {
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%
}
.facebook-btn, .social-facebook {
	display: flex;
	justify-content: center
}
.facebook-btn {
	align-items: center;
	background: none;
	border: 0;
	border-radius: 50%;
	cursor: pointer;
	height: 44px;
	margin: 1px;
	-webkit-transition: .3s;
	transition: .3s;
	width: 44px
}
@media (min-width:330px) {
	.facebook-btn {
		margin: 5px
	}
}
@media (min-width:600px) {
	.facebook-btn {
		margin: 7px
	}
}
@media (min-width:1025px) {
	.facebook-btn {
		margin: 10px
	}
}
.facebook-btn>svg {
	height: auto;
	-webkit-transition: -webkit-transform .3s;
	transition: -webkit-transform .3s;
	transition: transform .3s;
	transition: transform .3s, -webkit-transform .3s;
	width: 24px;
	fill: #507cbe
}
.facebook-btn:hover {
	background: #507cbe;
	box-shadow: 0 0 10px #507cbe
}
.facebook-btn:hover>svg {
	fill: #fff
}
@media (any-hover:none) {
	.facebook-btn::hover {
		background: none;
		box-shadow: none
	}
}